"use client";

// Definitions
import { ErrorBoundaryType } from "@/common/types/errors";

// Components
import { Button } from "@/client/components/ui/Button";

const AppErrorBoundary = ({ error, reset }: ErrorBoundaryType) => {
  return (
    <div>
      <p>
        <span>App error: </span> <span>{error.message}</span>
      </p>
      <p>
        <Button type="default" size="large" onClick={reset}>
          Try again
        </Button>
      </p>
    </div>
  );
};

export default AppErrorBoundary;
